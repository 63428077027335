import VeeValidate from "vee-validate";

function messages() {
    "use strict";
    var e,
        n = {
            name: "en",
            messages: {
                _default: function (e: any) {
                    return e + "को डाटा मान्य छैन";
                },
                after: function (e: any, n: any) {
                    var t = n[0];
                    return "The " + e + " must be after " + (n[1] ? "or equal to " : "") + t;
                },
                alpha: function (e: any) {
                    return "The " + e + " field may only contain alphabetic characters";
                },
                alpha_dash: function (e: any) {
                    return (
                        "The " +
                        e +
                        " field may contain alpha-numeric characters as well as dashes and underscores"
                    );
                },
                alpha_num: function (e: any) {
                    return "The " + e + " field may only contain alpha-numeric characters";
                },
                alpha_spaces: function (e: any) {
                    return "The " + e + " field may only contain alphabetic characters as well as spaces";
                },
                before: function (e: any, n: any) {
                    var t = n[0];
                    return "The " + e + " must be before " + (n[1] ? "or equal to " : "") + t;
                },
                between: function (e: any, n: any) {
                    return "The " + e + " field must be between " + n[0] + " and " + n[1];
                },
                confirmed: function (e: any) {
                    return "The " + e + " confirmation does not match";
                },
                credit_card: function (e: any) {
                    return "The " + e + " field is invalid";
                },
                date_between: function (e: any, n: any) {
                    return "The " + e + " must be between " + n[0] + " and " + n[1];
                },
                date_format: function (e: any, n: any) {
                    return "The " + e + " must be in the format " + n[0];
                },
                decimal: function (e: any, n: any) {
                    void 0 === n && (n = []);
                    var t = n[0];
                    return (
                        void 0 === t && (t = "*"),
                        "The " +
                        e +
                        " field must be numeric and may contain" +
                        (t && "*" !== t ? " " + t : "") +
                        " decimal points"
                    );
                },
                digits: function (e: any, n: any) {
                    return "The " + e + " field must be numeric and contains exactly " + n[0] + " digits";
                },
                dimensions: function (e: any, n: any) {
                    return "The " + e + " field must be " + n[0] + " pixels by " + n[1] + " pixels";
                },
                email: function (e: any) {
                    return e + " फिल्ड मान्य इमेल हुनुपर्छ";
                },
                excluded: function (e: any) {
                    return e + "को डाटा मान्य हुनुपर्छ";
                },
                ext: function (e: any) {
                    return e + " फिल्डको फाईल प्रकार मान्य हुनुपर्छ";
                },
                image: function (e: any) {
                    return "The " + e + " field must be an image";
                },
                included: function (e: any) {
                    return e + "को डाटा मान्य हुनुपर्छ";
                },
                integer: function (e: any) {
                    return "The " + e + " field must be an integer";
                },
                ip: function (e: any) {
                    return "The " + e + " field must be a valid ip address";
                },
                ip_or_fqdn: function (e: any) {
                    return "The " + e + " field must be a valid ip address or FQDN";
                },
                length: function (e: any, n: any) {
                    var t = n[0],
                        i = n[1];
                    return i
                        ? "The " + e + " length must be between " + t + " and " + i
                        : "The " + e + " length must be " + t;
                },
                max: function (e: any, n: any) {
                    return "The " + e + " field may not be greater than " + n[0] + " characters";
                },
                max_value: function (e: any, n: any) {
                    return e + " फिल्ड " + n[0] + " वा कम हुनुपर्छ";
                },
                mimes: function (e: any) {
                    return e + " फिल्डको फाइल प्रकार मान्य हुनुपर्छ";
                },
                min: function (e: any, n: any) {
                    return "The " + e + " field must be at least " + n[0] + " characters";
                },
                min_value: function (e: any, n: any) {
                    return e + " फिल्ड " + n[0] + " वा अधिक हुनुपर्छ";
                },
                numeric: function (e: any) {
                    return e + " फिल्डमा अंकहरू मात्रै हुनुपर्छ";
                },
                regex: function (e: any) {
                    return "The " + e + " field format is invalid";
                },
                required: function (e: any) {
                    return e + " फिल्ड आवाश्यक छ";
                },
                required_if: function (e: any, n: any) {
                    return "The " + e + " field is required when the " + n[0] + " field has this value";
                },
                size: function (e: any, n: any) {
                    return (
                        e +
                        " फाइलको साइज " +
                        (function (e: any) {
                            var n = 1024,
                                t = 0 === (e = Number(e) * n) ? 0 : Math.floor(Math.log(e) / Math.log(n));
                            return (
                                1 * (e / Math.pow(n, t) as any).toFixed(2) +
                                " " +
                                ["Byte", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][t]
                            );
                        })(n[0]) + "भन्दा कम हुनुपर्छ"
                    );
                },
                url: function (e: any) {
                    return e + " फिल्ड मान्य यु.आर.एल. होइन";
                },
                checkUserExists: function (e: any) {
                    return "यो ईमेल भएको प्रयोगकर्ता पहिले नै छ";
                },
                checkLicenseExists: function (e: any) {
                    return "यो अनुमति पत्र नं. भएको प्रयोगकर्ता पहिले नै छ";
                }
            },
            attributes: {}
        };
    let ret = (
        "undefined" != typeof VeeValidate &&
        VeeValidate.Validator.localize((n.name, e)), n
    );
    return ret;
}

export default messages();
